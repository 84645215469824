









import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class LinkedImageButton extends Vue {
  @Prop({ type: String, required: true }) readonly imageUrl!: string
  @Prop({ type: String, required: true }) readonly link!: string
  @Prop({ type: String, default: '' }) readonly text!: string
}
